<template>
  <div class="i-upload-container">
    <slot v-if="showImg">
      <div class="files-list" v-for="(item, index) in uploadFiles" :key="index">
        <img
          :style="
            'width:' +
            imgWidth +
            'px;height:' +
            imgHeight +
            'px; margin-right: 10px;background: #ccc;'
          "
          :src="item._fileSmall || item._file"
        />
        <div
          class="file-item-cover"
          :style="'width:' + imgWidth + 'px;height:' + imgHeight + 'px;'"
          @click="viewPic(item._file)"
        >
          <!--          <Icon type="ios-eye-outline" size="24" color="#ffffff"></Icon>-->
          <Icon
            type="ios-close-circle-outline"
            size="24"
            @click.native.stop="doRemove(item)"
            color="#ED1C24"
          ></Icon>
        </div>
        <div
          class="upload-percent"
          v-if="isUploading"
          :style="{ height: item.percent + '%' }"
        ></div>
      </div>
    </slot>
    <slot name="upload-btn"></slot>
    <Button class="upload-btn" type="dashed">
      <Icon :type="icon" size="20" color="#dcdee2"></Icon>
      <input
        type="file"
        class="file-input"
        ref="input"
        :multiple="multiple"
        @change="onChange($event)"
      />
    </Button>
    <Modal
      title="查看大图"
      class-name="i-modal"
      width="400"
      v-model="isShowViewImage"
      :footer-hide="true"
    >
      <img :src="ViewImg" v-if="isShowViewImage" style="width: 100%" />
      <template slot="footer">
        <Button @click="isShowViewImage = false">关闭</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import * as Qiniu from "qiniu-js";
import dayjs from "dayjs";
import random from "lodash/random";
import api from "@/api";
export default {
  name: "i-upload",
  props: {
    showImg: {
      type: Boolean,
      default: true,
    },
    compress: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "ios-camera",
    },
    formKey: String,
    fileKey: String, // 上传文件名KEY
    autoUpload: {
      type: Boolean,
      default: false,
    },
    region: {
      type: String,
      default: Qiniu.region.z2,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    max: { type: Number, default: 999 },
    quality: { type: Number, default: 1 },
    imgWidth: { type: Number, default: 58 },
    imgHeight: { type: Number, default: 58 },
  },
  data() {
    return {
      isShowViewImage: false,
      ViewImg: "",
      isUploading: false, // 上传中
      putExtra: {
        fname: "",
        params: {},
        mimeType: [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/webp",
          "video/mp4",
          "application/vnd.android.package-archive",
        ],
      },
      config: {
        useCdnDomain: true,
        region: this.region,
        forceDirect: true,
      },
      observable: [],
      uploadFiles: [],
      observer: {},
    };
  },
  watch: {
    isUploading(val) {
      if (!val) this.$refs.input.value = "";
    },
  },
  created() {},
  methods: {
    viewPic(src) {
      this.ViewImg = src;
      this.isShowViewImage = true;
    },
    doRemove(item) {
      const index = this.uploadFiles.findIndex(
        (cell) => cell._key === item._key
      );
      this.$refs.input.value = "";
      this.uploadFiles.splice(index, 1);
      this.$emit("doRemove", item);
    },
    async Upload() {
      try {
        const res = await api.GetQiniuToken();
        let subscriptions = [];
        let failList = [];
        const successList = this.uploadFiles
          .filter((item) => item.success)
          .map((item) => item._key);
        this.isUploading = true;
        return new Promise((resolve) => {
          if (successList.length === this.uploadFiles.length) {
            this.isUploading = false;
            resolve({ success: successList, fail: failList });
          } else {
            this.uploadFiles
              .filter((item) => !item.success)
              .forEach((item) => {
                const subscription = Qiniu.upload(
                  item.img,
                  item._key,
                  res.Data,
                  this.putExtra,
                  this.config
                ).subscribe(
                  (result) => {
                    item.percent = result.total.percent;
                    this.$emit("outputProgress", result.total.percent);
                    console.log(`${item._key}: ====> ${result.total.percent}%`);
                  },
                  (err) => {
                    // 失败以后放入 失败对象列表里面， 从上传列表中删除
                    failList.push(item._key);
                    const index = subscriptions.findIndex(
                      (sub) => sub.filename === item._key
                    );
                    if (index !== -1) subscriptions.splice(index, 1);
                    // 判断上传列表中是否还有数据，没有数据就返回resolve 上传结束
                    if (subscriptions.length === 0) {
                      this.isUploading = false;
                      // 返回失败列表做相应处理
                      console.log("文件全部上传完成");
                      resolve({ success: successList, fail: failList });
                    }
                    console.log(err);
                  },
                  (response) => {
                    successList.push(response.key);
                    // 成功以后从上传列表中删除
                    const index = subscriptions.findIndex(
                      (sub) => sub.filename === response.key
                    );
                    if (index !== -1) subscriptions.splice(index, 1);
                    // 判断上传列表中是否还有数据，没有数据就返回resolve 上传结束
                    if (subscriptions.length === 0) {
                      this.isUploading = false;
                      // 返回失败列表做相应处理
                      console.log("文件全部上传完成");
                      resolve({ success: successList, fail: failList });
                    }
                  }
                );

                subscriptions.push({ filename: item._key, subscription });
              });
          }
        });
      } catch (e) {
        this.$Notice.error({ title: "错误", desc: e });
      }
    },
    onChange(event) {
      if (!this.multiple) this.uploadFiles = [];
      const files = Object.values(event.target.files);
      if (this.uploadFiles.length + files.length > this.max) {
        this.$Notice.warning({ desc: `最多${this.max}个文件` });
        return;
      }
      files.forEach(async (file) => {
        if (!this.putExtra.mimeType.includes(file.type)) {
          this.uploadFiles = [];
          this.$Notice.error({
            title: "错误",
            desc: `图片${
              file.name
            }格式不正确，请上传${this.putExtra.mimeType.join(";")}格式的文件`,
          });
          return false;
        }
        try {
          const key = `ssdms_${this.fileKey}_${dayjs().format(
            "YYYYMMDDHHMMSS"
          )}_${random(999999)}${file.name.substr(
            file.name.lastIndexOf("."),
            file.name.length
          )}`;
          let img = file;
          if (this.compress) {
            const res = await Qiniu.compressImage(file, {
              quality: this.quality,
            });
            img = res.dist;
          }
          this.uploadFiles.push({
            _key: key,
            img,
            _file: window.URL.createObjectURL(file),
            success: false,
            percent: 0,
          });
          this.$emit("onChange", {
            type: this.formKey,
            files: this.uploadFiles,
          });
        } catch (error) {
          this.$Notice.error({
            title: "错误",
            desc: `文件${file.name}处理失败，请稍后重试:${error}`,
          });
          return false;
        }
      });
      console.log(this.uploadFiles);
    },
  },
  computed: {
    token() {
      return this.$store.state.app.qiniuToken;
    },
  },
};
</script>

<style lang="less">
.i-upload-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .files-list {
    width: auto;
    height: 58px;
    margin-bottom: 10px;
    position: relative;
    &:hover .file-item-cover {
      display: flex;
    }
    .file-item-cover {
      position: absolute;
      width: 58px;
      height: 58px;
      top: 0;
      left: 0;
      display: none;
      justify-content: flex-end;
      align-items: flex-start;
      border: 1px dashed #dcdee2;
      border-radius: 4px;
      & > i {
        cursor: pointer;
        position: relative;
        top: -6px;
        right: -6px;
      }
    }
    .upload-percent {
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      width: 58px;
      height: 100%;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
  .upload-btn {
    position: relative;
    width: 58px;
    height: 58px;
    cursor: pointer;
    .file-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
    }
  }
}
</style>
